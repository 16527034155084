@import url("https://fonts.googleapis.com/css2?family=East+Sea+Dokdo&display=swap");
@import "./materialUiOverwrite.css";
@import url("https://fonts.googleapis.com/css2?family=Lexend+Deca&display=swap");

body {
  margin: 0;
  padding: 0;
  font-family: "SUIT", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px !important;
  /*background-color: #e5e5e5 !important;*/
  background-color: #ffffff !important;
  color: #000 !important;
}

div {
  box-sizing: border-box;
}

a {
  text-decoration: none;
}

a:link,
a:visited {
  color: #2f80ed;
}

.mainBody {
  background-color: #e5e5e5;
}

.timeline_center_container {
  width: 100%;
  background-color: white;
  margin: auto;
  text-align: center;
  scroll-behavior: smooth;
}

.ant-card {
  font-size: 16px !important;
}

.ant-card-body {
  padding: 10px !important;
}

.ant-tabs {
  font-size: 16px !important;
}

textarea.ant-input {
  resize: none;
}

textarea.ant-input:hover {
  border-color: #d9d9d9 !important;
}

textarea.ant-input:focus {
  border-color: #d9d9d9 !important;
}

textarea.ant-input::selection {
  border-color: #d9d9d9 !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1 {
  font-size: 20px !important;
  font-weight: bold !important;
}

h2 {
  font-size: 18px !important;
  font-weight: bold !important;
}

h3 {
  font-size: 16px !important;
  font-weight: bold !important;
}

.center-container {
  width: 100%;
  max-width: 750px;
  margin: auto;
}

.bottom-bar {
  width: 95%;
  height: 40px;
  line-height: 30px;
  text-align: right;
  margin: auto;
}

.timelineTitle {
  text-align: center;
  font-weight: bold;
  font-size: 18px;
  background-color: white;
  padding: 15px;
  margin: 8px;
}

.ant-select-selector {
  border: 0 !important;
  background-color: transparent;
}

.ant-select-focused .ant-select-selector,
.ant-select-selector:focus,
.ant-select-selector:active,
.ant-select-open .ant-select-selector {
  border-color: #d9d9d9 !important;
  box-shadow: none !important;
}

textarea:hover,
input:hover,
textarea:active,
input:active,
textarea:focus,
input:focus,
button:focus,
button:active,
button:hover,
.ant-input-search:active,
.ant-input-search:focus,
.ant-input-affix-wrapper:hover,
.ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper:active,
label:focus,
.btn:active,
.btn.active {
  outline: 0 !important;
  -webkit-appearance: none;
  box-shadow: none !important;
}

.center-justified-container {
  justify-content: center;
  display: flex;
  flex-direction: column;
}

.ant-btn-primary {
  background-color: #fad962 !important;
  color: #6b6b6b !important;
  border-color: #fad962 !important;
}

.ant-btn-primary[disabled] {
  background-color: #fff6dc !important;
  border-color: #fff6dc !important;
}

.ant-btn-primary:hover {
  color: #ffffff !important;
}

.ant-btn-default {
  background-color: #a9a9a9 !important;
  color: #ffffff !important;
  border-color: #a9a9a9 !important;
}

.ant-btn-default:hover {
  background-color: #a9a9a9 !important;
  color: #6b6b6b !important;
  border-color: #a9a9a9 !important;
}

.card-icon {
  position: relative;
}

.clearFix:before,
.clearFix:after {
  content: " ";
  /* 1 */
  display: table;
  /* 2 */
}

.clearFix:after {
  clear: both;
}

/**
* For IE 6/7 only
* Include this rule to trigger hasLayout and contain floats.
*/
.clearFix {
  *zoom: 1;
}

.VictoryContainer {
  user-select: auto !important;
  pointer-events: auto !important;
  touch-action: auto !important;
}

.oxoSlideIn-enter {
  transform: perspective(250px) rotateX(-90deg);
}

.oxoSlideIn-enter.oxoSlideIn-enter-active {
  transform: perspective(350px) rotateX(0deg);
  transition: 500ms linear 1000ms;
}

.oxoSlideIn-appear {
  transform: perspective(250px) rotateX(-90deg);
}

.oxoSlideIn-appear.oxoSlideIn-appear-active {
  transform: perspective(350px) rotateX(0deg);
  transition: 500ms linear 1000ms;
}

.oxoRollOut-leave {
  max-height: 1000px;
}

.oxoRollOut-leave.oxoRollOut-leave-active {
  max-height: 0px;
  overflow: hidden;
  opacity: 0;
  transition: max-height 1000ms, opacity 1000ms ease-in;
}

.oxoRollOut-enter {
  opacity: 0;
  max-height: 0px;
  overflow: hidden;
}

.oxoRollOut-enter.oxoRollOut-enter-active {
  opacity: 1;
  max-height: 1000px;
  overflow: hidden;
  transition: max-height 1s linear 500ms, opacity 1000ms ease-in 500ms;
}

.oxoRollOut-appear {
  opacity: 0;
  max-height: 0px;
  overflow: hidden;
}

.oxoRollOut-appear.oxoRollOut-appear-active {
  opacity: 1;
  max-height: 1000px;
  overflow: hidden;
  transition: max-height 1s linear 500ms, opacity 1000ms ease-in 500ms;
}

.oxoFadeInDelayed-appear {
  opacity: 0;
}

.oxoFadeInDelayed-appear.oxoFadeInDelayed-appear-active {
  opacity: 1;
  transition: opacity 1000ms ease-in 500ms;
}

.oxoFadeIn-enter {
  opacity: 0.01;
}

.oxoFadeIn-enter.oxoFadeIn-enter-active {
  opacity: 1;
  transition: opacity 1000ms ease-in;
}

.oxoFadeIn-leave {
  opacity: 1;
  transition: opacity 1000ms ease-in;
}

.oxoFadeIn-leave.oxoFadeIn-leave-active {
  opacity: 0.01;
  transition: opacity 1000ms ease-in;
}

.oxoFadeIn-appear {
  opacity: 0.01;
}

.oxoFadeIn-appear.oxoFadeIn-appear-active {
  opacity: 1;
  transition: opacity 0.5s ease-in;
}

.ReactCollapse--collapse {
  transition: height 500ms;
}

/** 부족 tab  */

.ant-tabs-tab {
  margin: 0 7px !important;
}

.ant-tabs-tab.ant-tabs-tab-active {
  color: #000c17 !important;
  border-bottom-color: #000c17 !important;
}

.comment-li {
  list-style-image: url(../../img/quotation_mark.png);
  margin-bottom: 10px;
}

.ant-tabs-nav-list {
  margin: auto;
}

.title {
  font-size: 18px;
  font-weight: bold;
  white-space: normal;
}

.description {
  font-size: 16px;
  white-space: normal;
  word-break: keep-all;
}

.ant-carousel .slick-prev {
  z-index: 100 !important;
  font-size: 30px !important;
  color: #fddc00 !important;
  left: 0 !important;
}

.ant-carousel .slick-next {
  font-size: 30px !important;
  right: 5px !important;
  color: #fddc00 !important;
}

.presidentDecoration {
  background: url(../../img/president_decoration.png) no-repeat;
  background-size: 100%;
}

.ant-collapse {
  border-left: 0 !important;
  border-right: 0 !important;
  border-bottom: 0 !important;
}

.ant-collapse-header {
  border: 0 !important;
  font-size: 16px;
}

.ant-collapse-content {
  border: 0 !important;
}

.ant-collapse-content-box {
  padding: 15px !important;
}

.ant-modal {
  top: 20px !important;
  margin-bottom: 100px !important;
}

.adminTable td {
  border: solid 1px #dddddd;
  padding: 10px;
}

.adminTable {
  width: 100%;
}

.ant-timeline-item-content {
  font-size: 16px;
}

.ant-timeline-item-last {
  padding-bottom: 0 !important;
  margin-bottom: 0 !important;
  height: auto !important;
  min-height: 0 !important;
}

.ant-timeline-item-content {
  padding-bottom: 0 !important;
  margin-bottom: 0 !important;
  height: auto !important;
  min-height: 0 !important;
}

.ant-tabs-ink-bar {
  background-color: #9e9e9e !important;
}

.ReactCollapse--collapse {
  transition: height 2000ms;
}

.width100 {
  width: 100%;
}

@keyframes questionDetailsCard {
  0% {
    margin-top: 460px;
  }
  25% {
    margin-top: 440px;
  }
  50% {
    margin-top: 460px;
  }
  75% {
    margin-top: 440px;
  }
  100% {
    margin-top: 460px;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0.2;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0.2;
  }
}

.markdown_display img {
  max-width: 100%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

li {
  font-size: 16px;
}

.markdown_display ul,
ol {
  padding-left: 23px !important;
  margin-top: 0;
}

p {
  display: block;
  margin-block-start: 0;
  margin-block-end: 1em;
  margin-inline-start: 0;
  margin-inline-end: 0;
}

.markdown_display p {
  letter-spacing: -0em;
  line-height: 1.7em;
  font-size: 16px;
  word-break: break-word;
}

.markdown_display li {
  margin-block-end: 1em;
  line-height: 1.7em;
}

.markdown_display td {
  padding: 10px;
}

.markdown_display th {
  padding: 10px;
}

.smallerParagraph p {
  font-size: 14px !important;
}

.chatPreviewText p {
  font-size: 12px !important;
}

.chatline p {
  font-size: 14px !important;
  line-height: 1.5em;
  margin-block-end: 0;
}

.chatline li {
  font-size: 14px !important;
  line-height: 1.5em;
  margin-block-end: 0;
}

.chatline ol {
  line-height: 1.5em;
  margin-block-end: 0;
  margin-block-start: 0;
}

.chatInputDark::placeholder {
  color: #9e9e9e9e;
}

.chatInputWhite::placeholder {
  color: #d3d3d3;
}

textarea {
  font-size: 16px;
}

.fileContainer {
  text-align: center;
}

.fileContainer .chooseFileButton {
  margin: 0 !important;
  width: 100%;
  height: 41px;
  font-size: 14px !important;
}

.chatPhotoUploader .chooseFileButton {
  margin: 0 !important;
  height: 31px;
  width: 50px;
  padding: 0 !important;
  font-size: 14px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent !important;
  color: white !important;
  font-weight: bold !important;
}

.chatPhotoUploader .fileContainer {
  margin: 0 !important;
  padding: 0 !important;
}

.profilePhotoUploader .chooseFileButton {
  margin: 0 !important;
  width: 207px;
  height: 31px;
  padding: 0 !important;
  font-size: 14px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent !important;
  color: white !important;
  font-weight: bold !important;
}

.profilePhotoUploader .fileContainer {
  margin: 0 !important;
  padding: 0 !important;
}

a {
  word-wrap: break-word;
}

/* Algoria InstantSearch */
.ais-Pagination {
  margin-top: 1em;
}

.left-panel {
  margin: 0;
  padding-left: 6px;
}

.right-panel {
  margin: 0;
}

.ais-Menu-list {
  padding: 0 !important;
}

.ais-Hits-item {
  width: 360px;
  padding: 0;
  border: 0;
  margin: 0;
  box-shadow: none;
}

.ais-InfiniteHits-item {
  width: 360px;
  padding: 0;
  border: 0;
  margin: 0;
  box-shadow: none;
}

.hashcard_people_description .markdown_display p {
  font-size: 20px !important;
  line-height: 150%;
}

.markdown_display h1 {
  padding-top: 1em;
  font-size: 16px !important;
  line-height: 1.7em;
}

.markdown_display h1::before {
  text-align: left;
  content: "";
  display: block;
  border-top: 1px solid #666666;
  margin-top: 10px !important;
  position: relative;
  width: 60px;
  padding-bottom: 1.5em;
}

.markdown_display h2 {
  padding-top: 1em;
  font-size: 16px !important;
  text-align: left;
}

.text_agree {
  padding-top: 1.5em;
  font-size: 16px !important;
  font-weight: bold;
  text-align: left;
  content: "";
  display: block;
  width: 60px;
  border-top: 1px solid #666666;
  margin-top: 40px !important;
  padding-bottom: 1em;
}

.text_disagree {
  padding-top: 1.5em;
  font-size: 16px !important;
  font-weight: bold;
  text-align: left;
  content: "";
  display: block;
  width: 60px;
  border-top: 1px solid #666666;
  margin-top: 40px !important;
  padding-bottom: 1em;
}

.topic_markdown_o {
  height: 1em;
  margin-bottom: 3px;
  margin-right: 6px !important;
  display: inline-block !important;
  margin-left: 0 !important;
  vertical-align: middle !important;
}

.topic_markdown_x {
  height: 0.9em;
  margin-bottom: 3px;
  margin-right: 6px !important;
  display: inline-block !important;
  margin-left: 0 !important;
  vertical-align: middle !important;
  margin-right: 6px;
}

.ais-Menu-list {
  display: flex;
  flex-wrap: wrap;
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.ais-Hits-list {
  margin-top: 10px;
  margin-left: 0;
  padding: 0;
}

.ais-InfiniteHits-list {
  margin-top: 10px;
  margin-left: 0;
  padding: 0;
}

.ais-SearchBox {
  margin: 0px 10px;
  padding: 10px !important;
  border-radius: 20px;
  border-color: #eeeeee !important;
}

.ais-SearchBox-input {
  width: 100%;
  border-radius: 20px;
  font-size: 14px;
  padding-left: 33px;
}

.hit-name {
  padding: 0;
  margin: 0;
}

.ais-SearchBox-submit {
  margin: 0 5px !important;
}

.ais-Menu-item {
  background-color: inherit;
  border: solid 1px rgb(196, 200, 216);
  border-radius: 20px;
  padding: 3px 10px;
  margin: 4px 3px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
}

.ais-Menu-link {
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
}

.ais-Menu-label {
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
}

.ais-Menu-count {
  color: rgba(0, 0, 0, 0.85);
  background-color: inherit;
  padding: 0;
  margin-left: 2px;
  font-size: 1em;
}

.ais-Menu-item--selected {
  border: solid 1px #fedc3d;
  color: rgba(0, 0, 0, 0.85);
  background-color: #fedc3d !important;
  font-weight: normal;
}

.my-masonry-grid {
  display: -webkit-box; /* Not needed if autoprefixing */
  display: -ms-flexbox; /* Not needed if autoprefixing */
  display: flex;
  width: auto;
  margin-right: 10px;
  margin-left: 10px;
}

.my-masonry-grid_column {
  background-clip: padding-box;
}

/* Style your items */
.my-masonry-grid_column > div {
  /* change div to reference your elements you put in <Masonry> */
  display: flex;
  align-content: center;
  justify-content: center;
  margin-bottom: 10px;
}

.onboarding_text h2 {
  text-align: left;
  font-size: 20px !important;
}

.ReactCollapse--collapse {
  transition: height 600ms;
}

.lds-ellipsis::after {
  content: "";
  background-image: url("../../img/carrot.svg");
  background-size: 100% 100%;
  display: inline-block;
  height: 30px;
  width: 30px;
  position: relative;
  left: 45px;
  top: 19px;
  transform: rotate(30deg);
}

.tribe_nav_icon::after {
  position: absolute;
  width: 1px;
  right: 0;
  top: 17px;
  content: " ";
  height: 40%;
  background: rgba(0, 0, 0, 0.2);
}

.tribe_nav_icon:last-child::after {
  width: 0;
}

@keyframes opacityOnAndOff {
  0% {
    opacity: 0;
  }
  30% {
    opacity: 1;
  }
  70% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    display: none;
  }
}

.communityNotice_bubble::before {
  content: "";

  border-left: 20px solid transparent;
  border-bottom: 20px solid #e5e5e5;
  top: -35px;
  left: 275px;
  position: relative;
}

.intro-login::before {
  content: "";
  flex-grow: 1;
  background: #c7c7c7;
  height: 1px;
  font-size: 0px;
  line-height: 0px;
  margin-right: 16px;
  width: 140px;
}

.intro-login::after {
  content: "";
  flex-grow: 1;
  background: #c7c7c7;
  height: 1px;
  font-size: 0px;
  line-height: 0px;
  margin-left: 16px;
  width: 140px;
}

.topFixBannerShow {
  visibility: visible;
}

.topFixBannerHide {
  visibility: hidden;
}

.hashPage_story_preview {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.selectBoxA:focus {
  outline: 0;
}

/* On Chrome */
.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

/* For Firefox and IE */
.hide-scrollbar {
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.chatline img {
  border-radius: 15px;
}

.centerText {
  display: flex;
  align-items: center;
}

@keyframes visibility {
  0% {
    opacity: 80%;
    width: 110%;
    height: 110%;
  }
  50% {
    opacity: 50;
    width: 120%;
    height: 120%;
  }
  100% {
    opacity: 0;
    width: 100%;
    height: 100%;
  }
}

.showOxExpression {
  animation-name: visibility;
  animation-duration: 1s;
}

.hideOxExpression {
  visibility: hidden;
}

@keyframes goUp {
  from {
    transform: translateY(36px);
  }
  to {
    transform: translateY(0px);
  }
}

.stanceTestResultBall_goUp {
  animation: goUp 1s;
  animation-fill-mode: backwards;
}

.todayTimelineBanner .slick-dots {
  bottom: 5px;
}

.todayTimelineBanner .slick-dots li {
  margin: 0px -2px;
}

.slick-dots li button:before {
  font-size: 6px;
}

/* Tiny Link Styles */

.react_tinylink_card {
  border-radius: 5px !important;
  text-decoration: unset !important;
  /* box-shadow: unset; */
  min-height: 70px;
  max-width: 500px !important;
  min-width: 200px !important;
  width: 100% !important;
  color: #000000 !important;
  background-color: #ffffff !important;
  border: 0px !important;
}

.react_tinylink_card_content_header {
  height: 20px !important;
  min-height: 0px !important;
}

.react_tinylink_card_content_wrapper {
  height: 20px !important;
  line-height: 30px !important;
  margin-top: 0px !important;
  overflow: hidden !important;
  flex-basis: 65px !important;
}
.IDGvz {
  height: unset !important;
}

.react_tinylink_card_media {
  flex-basis: 180px !important;
  background-size: cover !important;
}

.react_tinylink_card_content_header_description {
  /* display: unset !important; */
  font-size: 14px !important;
  top: 0px;
  box-shadow: unset;
}

.react_tinylink_card_content_description {
  display: none !important;
  color: #646464 !important;
  font-size: 8px !important;
  box-shadow: unset;
}

.react_tinylink_footer {
  /* display: none !important; */
  font-size: 8px !important;
  color: #949494 !important;
}

@media (max-width: 600px) {
  .mainBody {
    background-color: white;
  }
}
