.MuiPagination-ul {
  padding: 0 !important;
}

.MuiDrawer-paperAnchorDockedLeft {
  background-color: #2f2f2f !important;
  border-right: none !important;
}

.MuiDialogContent-root {
  padding: 5px !important;
}

.MuiTypography-body1 {
  color: #363636;
}

.MuiTableCell-root {
  border: 0;
}

.MuiTableCell-body {
  border: 0;
}

.MuiFab-root {
  box-shadow: none !important;
}

.MuiPaper-elevation1 {
  box-shadow: none !important;
}

.MuiAccordionSummary-content {
  margin: 0 !important;
}

.MuiAccordion-root {
  width: 100% !important;
  margin-bottom: 0 !important;
}

.MuiAccordionSummary-root.Mui-expanded {
  min-height: 0 !important;
}

.MuiCollapse-container {
  background-color: #fafafa !important;
}

.MuiDrawer-paper {
  overflow-x: hidden !important;
}

.MuiAccordionDetails-root {
  padding: 10px !important;
}

.MuiAccordion-root:before {
  display: none !important;
}

.MuiAccordionSummary-root {
  padding: 0 10px !important;
  background-color: #f0f0f0 !important;
  box-shadow: none !important;
}

.MuiTableCell-root {
  border: 0 !important;
}

.MuiTableCell-body {
  border: 0 !important;
}

.MuiToolbar-root {
  border: 0 !important;
}

.MuiButtonBase-root.MuiIconButton-root.MuiAutocomplete-popupIndicator {
  margin-right: 0;
}

.MuiDrawer-paperAnchorBottom {
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
}

.MuiDialog-paper {
  margin: 0;
}

.MuiDialog-paperWidthSm {
  min-width: 100%;
  min-height: 100%;
}

.MuiDialogContent-root {
  overflow-y: hidden;
}

.MuiDialog-container .MuiPaper-root {
  border-radius: 0;
}

.transparentPaperPlease .MuiPaper-root {
  background: transparent !important;
  box-shadow: none !important;
}

.MuiDialogContent-root {
  padding: 0 !important;
}

.backgroundWhite .MuiBackdrop-root {
  background-color: white;
  filter: "blur(100px)";
  padding: 0 !important;
  margin: 0 !important;
}

.cardPopup .MuiPaper-root {
  padding: 0;
  max-width: 100% !important;
  max-height: 100%;
  box-shadow: none !important;
  background-color: transparent;
}

.cardPopup .MuiDialog-paper {
  margin: 0 !important;
}

.MuiBackdrop-root {
  top: -80px !important;
  backdrop-filter: blur(15px);
}

.profileEditPage .MuiInputBase-input {
  font-size: 14px;
}
